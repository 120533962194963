@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
.master-refund {
  background: #f7f9ff;
  font-family: "Noto Sans", sans-serif;
  overflow-x: hidden;
}
.content-wrapper-refund {
  text-align: start;
  padding-left: 60px;
  margin-top: 30px;
}
.body-cr {
  line-height: 32px;
}
.intro-cAndr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin-left: 52%;
}
.head-refund {
  margin-top: 40px;
}
.sub-head {
  font-weight: 600;
  font-size: 16px;
  margin-top: 50px;
}
.paragraph {
  padding-right: 50px;
}
.paragraph-link {
  font-weight: 500;
}
.link-style {
  text-decoration: none;
  color: #27ae60;
  font-weight: 500;
}
.paragraph-highlight {
  font-weight: bold;
  margin-left: 0;
  /* margin-right: 24%; */
}
.sub-intro-c-r {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 20px;
}
.line-refund {
  background-color: #1c7d45;
  padding: 1px;
  text-align: start;
  margin: 0px;
  border-radius: 10px;
}
.c-r-text {
  font-family: "Noto Sans", sans-serif;
  color: #1c7d45;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  width: 500px;
  text-align: left;
}
.bz-cr-desc {
  width: 400px;
  line-height: 32px;
}
.intro-part1-cr {
  display: flex;
  justify-content: space-around;
}
.refund-img {
  margin-bottom: -160px;
}
.head-cr {
  font-size: 32px;
}
@media screen and (max-width: 767px) {
  .intro-cAndr {
    flex-direction: column;
  }
  .head-cr {
    font-size: 24px;
  }
  .refund-img {
    width: 300px;
    height: 300px;
  }
  .content-wrapper-refund {
    text-align: start;
    padding-left: 0px;
    margin-top: 30px;
  }
  .paragraph {
    padding-right: 0px;
  }
  .c-r-text {
    font-family: "Noto Sans", sans-serif;
    color: #1c7d45;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 54px;
    width: 300px;
    text-align: center;
  }
  .line-refund {
    display: none;
  }
  .sub-intro-c-r {
    text-align: start;
  }
  .intro-part1-cr {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .body-cr {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 160px;
  }
  .intro-cAndr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-left: 0;
  }
  .bz-cr-desc {
    width: 300px;
  }
}
