.timer-parent {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-top: 4%;
}

.timer {
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 20%;
}

.timer-text {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 25%;
  font-size: small;
}

.countdown {
  border: 1px solid white;
  width: 70px;
  height: 70px;
  border-radius: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: white;
}
@media screen and (max-width: 400px) {
    .timer-parent {
        display: flex;
        justify-content: space-between;
        width: 60%;
        margin-top: 4%;
      }
    .countdown {
        border: 1px solid white;
        width: 50px;
        height: 50px;
        border-radius: 10%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: white;
        padding: 5px 15px;
        margin-right: 10px;
      }
}

/* @media only screen and (max-width: 600px) {
    .timer-parent {
        width: 20%;
        margin-left: 5%;
        margin-top: 2%;
    }

    .countdown {
        margin-right: 10%;
        min-width: 40px;
        min-height: 40px;
        padding: 0 10%;
    }
} */
