.input-form {
  /* margin-left: 10%; */
  margin: 15% 0 0 0;
  color: white;
}

.val {
  display: flex;
}

.form-row-message {
  margin-bottom: 3%;
}
.key {
  margin-bottom: 0px;
}
.input-id {
  width: 300px;
  height: 20px;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 10px;
  color: white;
}

.signup-button {
  background-color: #4d7df0;
  border: none;
  border-radius: 8px;
  color: white;
  margin-left: 10px;
  width: 100px;
  padding: 13px 10px;
  font-size: 14px;
  cursor: pointer;
}

.alert {
  margin-top: 3%;
}
.Confirmation {
  color: #1dc085;
  width: 300px;
}

@media only screen and (max-width: 767px) {
  .input-form {
    /* margin-left: 10%; */
    margin: 10% 0 0 0;
    color: white;
  }
  .input-id {
    width: 200px;
    height: 20px;
    background-color: transparent;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 10px;
  }
  .key {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .form-row-message {
    margin-bottom: 2%;
  }
}
