@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
.master {
  background: #f7f9ff;
  font-family: "Noto Sans", sans-serif;
}
.content-wrapper-terms {
  text-align: start;
  padding-left: 60px;
  margin-top: 30px;
}
.head-tc {
  margin-top: 40px;
}
.sub-head {
  font-weight: 600;
  font-size: 16px;
  margin-top: 50px;
}
.paragraph {
  padding-right: 50px;
}
.paragraph-link {
  font-weight: 500;
}
.link-style {
  text-decoration: none;
  color: #27ae60;
  font-weight: 500;
}
.paragraph-highlight {
  font-weight: bold;
}
.terms-img {
  margin-bottom: -160px;
  width: 300px;
}
.privacy-policy-text {
  width: 400px;
}
.intro {
  display: flex;
  justify-content: space-between;
}
.body {
  padding-top: 100px;
}

@media screen and (max-width: 767px) {
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .terms-img {
    width: 300px;

    margin-bottom: -100px;
  }
  .content-wrapper-terms {
    text-align: start;
    padding-left: 0px;
    padding-top: 40px;
  }
  .paragraph {
    padding-right: 0px;
  }
  .privacy-policy-text {
    text-align: center;
    width: 300px !important;
  }
  .line {
    display: none;
  }
}
