@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

.pricing-tagline {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9b9a9a;
}
.content1 {
  font-weight: 500;
  font-size: 30px;
  color: #9b9a9a;
}
.content2 {
  font-size: 12px;
  font-weight: 500;
  color: #9b9a9a;
  margin-top: 10px;
}
.questions {
  font-style: normal;
  font-weight: 400;
  font-size: 16.8601px;
  color: #9b9a9a;
  text-align: start;
  margin-top: 50px;
}
.Greentext {
  color: #53cd60;
}
.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  font-family: "Quicksand", sans-serif;
}
.head1 {
  margin-top: 75px;
  display: flex;
  width: 100%;
}
.pricing-img {
  display: flex;

  flex-basis: 50%;
  justify-content: flex-end;
}
.pricing-content {
  display: flex;

  flex-basis: 50%;
  justify-content: flex-start;
  flex-direction: column;
  text-align: start;
  padding-top: 50px;
}
.pricing {
  font-size: 36px;
  font-family: "Quicksand", sans-serif;
  color: #1c7d45;
  margin: 10px 0px;
}
.contact-content {
  text-align: start;
}
.static-bgs {
  position: absolute;
}
.img1 {
  position: relative;
  top: -150px;
  left: 50px;
}
.img2 {
  position: relative;
  top: -100px;
  left: 100px;
}
.img3 {
  position: relative;
  top: -200px;
  left: 300px;
}
.img4 {
  position: relative;
  top: -400px;
  left: 500px;
}
.contactUs {
  background: #4d7df0;
  border-radius: 4.52383px;
  border: none;
  padding: 10px 30px;
  margin-top: 20px;
}
.bd-get-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .content1 {
    font-size: 16px;
  }
  .static-bgs {
    display: none;
  }
  .Wrapper {
    padding: 0px;
  }

  .head1 {
    flex-direction: column;
    align-items: center;
  }
  .pricing-img {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: normal;
  }
  .contact-content {
    margin-bottom: 50px;
    text-align: center;
  }
  .pricing-content {
    text-align: center;
  }
  .questions {
    text-align: center;
  }
}
