@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:display=swap");

.bd-parent {
  padding: 100px 0;
  margin-top: 80px;
}
.bd-head {
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  margin-top: 0;
}
.bd-child-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.bd-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 100px;
  width: 500px;
}
.bd-heading-feature {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 49px;
}
.bd-description {
  width: 500px;
  color: #4f4f4f;
}
.bd-child-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.bd-child-3 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.bd-child-4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
}
/* Images */
.bd-iPadPro {
  width: 400px;
  z-index: 10;
}
/* .bd-cs-img {
  width: 600px;
} */
.bd-lap-img {
  width: 550px;
}
.bd-cirWoman {
  width: 450px;
  margin-left: 130px;
}
.crm {
  width: 500px;
}

/* semi-circle */
.semi-cir {
  height: 600px;
  width: 700px;
  background: linear-gradient(to bottom, #b2e9b7 55%, #eefcef 45%);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-100%, -50%);
}

@media screen and (max-width: 767px) {
  .bd-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin-top: 0;
  }
  .bd-head {
    width: 310px;
    font-size: 24px;
    text-align: center;
  }
  .bd-heading-feature {
    font-size: 20px;
    margin: 0;
    z-index: 15;
  }
  .bd-description {
    width: 300px;
    font-size: 13px;
    margin: 0;
  }
  .bd-text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-top: 15px;
    width: 320px;
  }

  /* semi-circle */
  .semi-cir {
    position: absolute;
    top: 20%;
    left: 25%;
    width: 500px;
    height: 400px;
  }

  /* children */
  .bd-child-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
  }
  .bd-child-2 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }
  .bd-child-3 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 200px;
  }
  .bd-child-4 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }

  /* images */
  .bd-iPadPro {
    margin-top: -260px;
    width: 300px;
  }
  .bd-lap-img {
    width: 300px;
  }
  .bd-cirWoman {
    width: 84%;
    margin-left: 30px;
  }
  .crm {
    width: 300px;
  }
}
