body {
  background: #19253d;
  color: #ffffff;
  width: 100vw;
}
.wrapper {
  background-color: #19253d;
  display: flex;
  overflow-x: hidden;
  padding-left: 119px;
  height: 100vh;
}
.bOS-txt-img {
  margin-top: 100px;
  width: 800px;
}
.bOS-img {
  position: relative;
  right: 100px;
  top: 100px;
  width: 800px;
  height: 550px;
  z-index: 1;
}
.Group1 {
  position: absolute;
  left: 37%;
  top: 7%;
  width: 50px;
  opacity: 0.5;
}
.Group2 {
  position: absolute;
  left: 40%;
  top: 85%;
  opacity: 0.5;
  width: 100px;
}
.Group3 {
  position: absolute;
  left: 0%;
  top: 48%;
  opacity: 0.5;
  width: 150px;
}
.Group4 {
  position: absolute;
  left: 65%;
  top: 60%;
  opacity: 0.5;
}
.Group5 {
  position: absolute;
  left: 0%;
  top: 0%;
  opacity: 0.5;
  width: 150px;
}
.Group6 {
  position: absolute;
  left: 65%;
  top: 7%;
  opacity: 0.5;
  width: 350px;
}
@media screen and (max-width: 767px) {
  .wrapper {
    background-color: #19253d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 6%;
    overflow-y: hidden;
  }
  .bOS-txt-img {
    position: relative;
    margin-top: 50px;
    width: 500px;
  }
  .bOS-img {
    z-index: 1;
    width: 100%;
    height: 330px;
    top: 0px;
    right: -40px;
    overflow-y: hidden;
  }
  .Group1 {
    position: absolute;
    left: 37%;
    top: 2%;
    width: 50px;
    opacity: 0.5;

  }
  .Group2 {
    position: absolute;
    left: 80%;
    top:50%;
    opacity: 0.5;
    width: 50px;
  }
  .Group3 {
    position: absolute;
    left: 0%;
    top: 63%;
    width: 100px;
  }
  .Group4 {
    position: absolute;
    left: 30%;
    top: 50%;
    opacity: 0.5;
  }
  .Group5 {
    position: absolute;
    left: 0%;
    top: -10%;
    opacity: 0.5;
    width: 100px;
  }
  .Group6 {
    position: absolute;
    left: 65%;
    top: 0%;
    width: 100px;
  }
}
