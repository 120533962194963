@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:display=swap");

.fq-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  background-color: #f7f9ff;
  padding-top: 100px;
}
.fq-head {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
}
.questions-logo-wrapper {
  display: flex;
}
.faq-questions {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #19253d;
  width: 1000px;
  text-align: left;
}
.plus-icon {
  width: 25px;
  height: 50px;
}
.plus-icon:hover {
  cursor: pointer;
}
.horizontal-line {
  width: 200px;
}
.qns-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.faq-answers {
  text-align: left;
  width: 900px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 150%;
  color: #4f4f4f;
}
.qns-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 100px;
}
.btn-faq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 169px;
  height: 45px;
  background: #4d7df0;
  border-radius: 5px;
  border: none;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 40px;
  box-shadow: 0px 100px 80px rgba(49, 49, 49, 0.1),
    0px 64.8148px 46.8518px rgba(49, 49, 49, 0.0759259),
    0px 38.5185px 25.4815px rgba(49, 49, 49, 0.0607407),
    0px 20px 13px rgba(49, 49, 49, 0.05),
    0px 8.14815px 6.51852px rgba(49, 49, 49, 0.0392593),
    0px 1.85185px 3.14815px rgba(49, 49, 49, 0.0240741);
}
.btn-faq:hover {
  cursor: pointer;
}
.para-faq {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #19253d;
}
/*Vector Images*/
.vector1 {
  position: absolute;
  top: 4400px;
  left: 250px;
  opacity: 0.6;
}
.vector2 {
  position: absolute;
  top: 4500px;
  left: 490px;
  opacity: 0.6;
}
.vector3 {
  position: absolute;
  top: 4510px;
  left: 820px;
  opacity: 0.6;
}
.vector4 {
  position: absolute;
  top: 4480px;
  left: 1100px;
  opacity: 0.6;
}

.faq-hr-line {
  width: 1000px;
  color: black;
  opacity: 0.2;
}
@media only screen and (max-width: 767px) {
  .vector1,
  .vector4 {
    display: none;
  }
  .vector2 {
    position: absolute;
    left: 0;
    top: 4000px;
  }
  .vector3 {
    position: absolute;
    left: 300px;
    top: 4050px;
  }
  .fq-head {
    width: 340px;
    font-size: 24px;
  }
  .faq-questions {
    width: 300px;
  }
  .faq-answers {
    width: 280px;
  }
  .fq-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 330px;
  }
  .questions-logo-wrapper {
    width: 320px;
  }
  .qns-btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .plus-icon {
    margin-top: 10px;
  }
  .para-faq {
    width: 250px;
  }
  .btn-faq {
    margin-left: 0;
  }
  .faq-hr-line {
    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 350px) {
  .fq-parent {
    width: 320px;
  }
  .faq-questions {
    width: 269px;
  }
  .faq-answers {
    width: 260px;
  }
  .questions-logo-wrapper {
    width: 300px;
  }
  .fq-head {
    width: 300px;
    font-size: 20px;
  }
  .vector2 {
    position: absolute;
    left: -10px;
    top: 4080px;
  }
  .vector3 {
    position: absolute;
    left: 250px;
    top: 4120px;
  }
}
