body {
  overflow-x: hidden;
}
.ld-1st-row {
  padding: 0 100px;
  max-height: 80vh;
  background: url("../images/video-background.svg"),
    radial-gradient(77.85% 119.6% at 87.78% 76.59%, #27ae60 0%, #197741 100%);
  background-repeat: no-repeat;
  background-position: bottom;
}
.ld-2nd-row {
  background-color: #ffffff;
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
}
.ld-3rd-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 100px;
}

.ld-4th-row {
  margin-top: 200px;
  background: linear-gradient(263.27deg, #19253d 44.57%, #233a68 116.39%);
}
@media screen and (max-width: 767px) {
  .ld-1st-row {
    background: url("../images/video-background.svg"),
      radial-gradient(77.85% 119.6% at 87.78% 76.59%, #27ae60 0%, #197741 100%);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    padding: 0;
    margin: 0;
    max-height: 89vh;
  }
  .ld-2nd-row {
    margin-left: 0;
    margin-right: 0;
  }
  .ld-3rd-row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .ld-4th-row {
    margin-top: 0;
  }
}

@media screen and (min-width: 318px) and (max-width: 375px) {
  .ld-1st-row {
    max-height: 87vh;
  }
}
