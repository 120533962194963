@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
.master {
  background: #f7f9ff;
}
.logo-head {
  padding-left: 60px;
  padding-right: 60px;
  background: #edfff5;
}
.head-img-pc {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.privacy-img {
  margin-bottom: -200px;
  margin-top: 50px;
}
.body {
  text-align: center;
  line-height: 32px;
  margin-top: -30px;
  padding: 20px;
  font-family: "Noto Sans", sans-serif;
}
.privacy-intro-pc {
  width: 80%;
  line-height: 25px;
}
.content-wrapper {
  text-align: start;
  padding-left: 60px;
  margin-top: 30px;
}
.head {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-align: justify;
  color: #484747;
  margin-top: 40px;
}
.sub-head {
  font-weight: 700;
}
.paragraph {
  padding-right: 50px;
}
.paragraph-link {
  font-weight: 500;
}
.link-style {
  text-decoration: none;
  color: #27ae60;
  font-weight: 500;
}
.paragraph-highlight {
  font-weight: bold;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  margin: auto;
}
th {
  width: 300px;
}
.updated-on {
  margin-top: 50px;
}
.privacy-footer {
  background: linear-gradient(263.27deg, #19253d 44.57%, #233a68 116.39%);
}
.intro-pc {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 0;
}
.intro-part1 {
  display: flex;
  justify-content: center;
  flex-basis: 40%;
}
.intro-part2-pc {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  margin-left: auto;
  margin-right: 12%;
}
.privacy-policy-text {
  font-family: "Noto Sans", sans-serif;
  color: #1c7d45;
  margin-left: 200px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
}
.line {
  background-color: #1c7d45;
  padding: 1px;
  width: 300px;
  text-align: start;
  margin: 0px;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  .logo-head {
    padding: 0;
  }
  .intro {
    flex-direction: column;
  }
  .head-img-pc {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .privacy-intro-pc {
    width: 280px;
    padding-top: 60px;
  }
  .privacy-img {
    width: 300px;
    margin-bottom: -100px;
    margin-top: 0;
  }
  .logo-head {
    padding-left: 0px;
    padding-right: 0px;
  }
  .content-wrapper {
    text-align: start;
    padding-left: 0px;
    margin-top: 30px;
  }
  .head {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
    color: #484747;
    margin-top: 10px;
  }
  .paragraph {
    padding-right: 0px;
  }
  .privacy-intro {
    width: 100%;
    margin-left: 0;
    margin-top: 100px;
  }
  .pricing-tagline {
    text-align: center;
  }
  .privacy-policy-text {
    width: 200px !important;
    text-align: center;
    margin-left: 0;
    font-size: 24px;
    line-height: 34px;
  }
  .line {
    display: none;
  }
  .body {
    padding: 10px;
  }
  .pc-table {
    width: 310px;
  }
}
