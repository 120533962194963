@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:display=swap");

.content-parent {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.ct-bizWoman {
  width: 600px;
}
.child1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ct-biz-head {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 0;
}
.ct-biz-para {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  width: 650px;
  margin: 25px 0 50px 0;
}
.ct-inp-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-demo-btn {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  background-color: #4d7df0;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  left: calc(50% - 117px / 2 - 280.5px);
  top: calc(50% - 40px / 2 + 163.5px);
  border-radius: 6px;
  border: none;
  padding: 12px;
}
.ct-demo-btn:hover {
  cursor: pointer;
}
.ct-biz-head-mobile {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 0;
}

.video-biz {
  border: 12px solid black;
  border-radius: 10px;
  margin-top: 45px;
}

@media screen and (max-width: 767px) {
  .content-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .child1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .ct-biz-head-mobile {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin: 0;
  }

  .ct-input-email {
    width: 320px;
  }
  .ct-bizWoman {
    width: 90%;
    padding: 8px;
  }

  .ct-biz-para {
    width: 314px;
    margin-bottom: 20px;
  }
  .ct-biz-head {
    display: none;
  }
  .ct-biz-head-mobile {
    display: block;
  }

  .video-biz {
    width: 300px;

    border: 8px solid black;
  }
}

@media screen and (max-width: 320px) {
  .video-biz {
    width: 230px;
    margin-top: 60px;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .video-biz {
    width: 270px;
  }
}
