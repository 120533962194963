@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:display=swap");
body {
  background-color: #f7f9ff;
  color: #282828;
}
.nv-wrapper {
  display: flex;
  justify-content: space-between;
}
.wrapper-routes {
  display: flex;
  justify-content: space-between;
  /* padding-top: 30px; */
}
.ld-bizos-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ld-circle-logo {
  margin-right: 5px;
}
.bz-heading {
  color: #ffffff;
  font-size: 38px;
}
.bz-heading-routes {
  color: black;
  font-size: 38px;
}
.ld-qn-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bz-sign-button {
  color: #27ae60;
  background-color: #ffffff;
  padding: 8px 14px;
  border: none;
  border-radius: 10px;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
}
.bz-sign-button:hover {
  cursor: pointer;
}
.signIn-routes {
  color: #ffffff;
  background-color: #27ae60;
  padding: 8px 14px;
  border: none;
  border-radius: 10px;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
}
.signIn-routes:hover {
  cursor: pointer;
}
.bz-faq-text {
  color: #777777;
  font-family: "DM Sans", sans-serif;
  margin-right: 40px;
  display: none;
}
.bz-faq-text:hover {
  cursor: pointer;
}
.bz-nav-contactus {
  text-decoration: none !important;
  color: #ffffff;
}
.nav-routes {
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  color: #ffffff;
  font-size: 16px;
  margin: 25px;
}
.nav-routes-black {
  display: none;
}
.nav-routes:hover {
  cursor: pointer;
}

.moible-screen-nav {
  display: none;
}
.nv-button-wrap {
  display: flex;
  justify-content: space-between;
}
.hide-faq {
  display: none;
}
.show-faq {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: #777777;
  margin-top: 32px;
  margin-right: 28px;
}
.show-faq:hover {
  cursor: pointer;
}
.mobileLogo {
  width: 40px;
  margin-right: 5px;
}
.bz-routes-logo-img {
  display: flex;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .nv-wrapper {
    display: none;
  }
  .ld-circle-logo {
    margin-top: 0;
  }
  .ld-bizos-logo {
    margin-top: 0;
    padding-top: 50px;
  }
  .nv-ham-menu {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    top: -50px;
    display: block;
    transition: top 0.4s;
    height: 50px;
    z-index: 50;
  }

  .nv-ham-menu1 {
    display: flex;
    border-radius: 3px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    z-index: 50;
  }
  .ham-menu {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  .nav-close-btn {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 55px;
  }
  .nv-mobile-nav {
    position: fixed;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    height: 100%;
    width: 100%;
    z-index: 40;
  }
  .nav-routes-mobile {
    font-weight: 600;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 25px;
    text-align: center;
    color: #4f4f4f;

    width: 270px;
    padding: 20px;
    border-radius: 10px;
    font-style: normal;
    line-height: 150%;
  }
  .nav-routes-mobile:hover {
    background-color: #edfff5;
  }
  .mobileLogo {
    width: 30px;
    margin-right: 0;
  }
  .mobileLogoOut {
    left: 130px;
    top: 15px;
    width: 40px;
    margin-right: 5px;
  }
  .nv-mob-bizos {
    font-size: 30px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    margin: 0 0 0 7px;
    color: #19253d;
  }
  .moible-screen-nav {
    display: block;
  }

  .scroll-nav-logo-div {
    position: fixed;
    left: 10px;
    transition: 0.3s;
    padding-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .scroll-nav-logo-div-open {
    position: fixed;
    left: 32%;
    transition: 0.3s;
    padding-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .show-faq {
    display: none;
  }
  .signIn-routes {
    display: none;
  }
  .bz-heading-routes {
    /* display: none; */
  }
  .wrapper-routes {
    display: none;
  }
  .bz-mobileNav-contactus {
    text-decoration: none !important;
    color: #4f4f4f;
  }
}
