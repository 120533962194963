@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:display=swap");

.pricing-head {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
}
.price-wrapper {
  background-color: #27ae60;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  color: #ffffff;
  width: 500px;

  padding: 20px 20px 10px 20px;
  border-radius: 10px;
  z-index: 3;
  font-family: "Noto Sans", sans-serif;
}
.price-child1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 30px;
}
.price-child2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-right: 30px;
}
.price-child3 {
  width: 100%;
  margin-top: 20px;
}
.pc-free-message {
  width: 180px;
  font-size: 13px;
  text-align: left;
}
.price-free {
  border: 2px solid #ffffff;
  padding: 7px 0;
  width: 60px;
}
.pc-get-started {
  background-color: #ffffff;
  border: none;
  width: 85%;
  color: #27ae60;
  padding: 10px;
  border-radius: 5px;
}
.pc-get-started:hover {
  cursor: pointer;
}

.conditiions-message {
  color: #ffffff;
  opacity: 0.6;
  font-size: 13px;
}
.price-tag {
  margin-top: 0;
  margin-bottom: 0;
}
.beta-price {
  color: #ffffff;
}
.vertical {
  opacity: 0.7;
}
.feature-points {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tick {
  margin-right: 12px;
}
/* vector images */
.p-circle {
  position: absolute;
  width: 417.8px;
  left: 840px;
  top: 3050px;
  opacity: 0.3;
}
.dollar {
  position: absolute;
  width: 40px;
  top: 3020px;
  left: 1000px;
  opacity: 0.2;
}
.dollar1 {
  position: absolute;
  width: 70px;
  left: 1250px;
  top: 3200px;
  opacity: 0.2;
}
@media screen and (max-width: 767px) {
  /* vector images */
  .p-circle {
    display: none;
  }
  .dollar {
    display: none;
  }
  .dollar1 {
    display: none;
  }
  .price-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 350px;
    justify-content: space-between;
    text-align: center;
    padding: 5px 10px 5px 10px;
  }
  .pricing-head {
    width: 150px;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .price-child1 {
    justify-content: center;
    align-items: left;
    margin-left: 10px;
    padding: 0;
  }
  .price-free {
    width: 50px;
    font-size: 13px;
  }
  .price-tag {
    font-size: 24px;
  }
  .pc-per-user {
    width: 140px;
    font-size: 15px;
  }
  .price-child2 {
    margin-right: 0;
  }

  .pc-all-features {
    font-size: 15px;
  }
  .pc-50-users {
    font-size: 15px;
  }
  .pc-free-message {
    font-size: 13px;
  }
}

@media (min-width: 300px) and (max-width: 350px) {
  .pc-per-user {
    width: 60px;
    font-size: 15px;
  }
  .pc-free-message {
    width: 120px;
    font-size: 13px;
  }
  .price-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    justify-content: space-around;
    text-align: center;
    padding: 5px 10px 5px 10px;
  }
  .price-child1 {
    justify-content: center;
    align-items: center;
    margin-left: 0;
    padding: 0;
  }
}
