@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:display=swap");

.bot-parent {
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 40;
}
.bot-btn {
  background-color: #4d7df0;
  border-radius: 50%;
  padding: 15px 17px;
  border: none;
  height: 75px;
  width: 75px;
  float: right;
}
.bot-btn:hover {
  cursor: pointer;
}
.bot-msg {
  margin-left: auto;
  background-color: #ffffff;

  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  padding: 30px;
  border-radius: 25px;
  max-width: 35%;
  margin-bottom: -35px;
  margin-right: 10px;
}
.bot-logo {
  width: 25px;
}
.bot-popup {
  width: 100%;
}
.bot-txt {
  color: #27ae60;
}
@media screen and (max-width: 767px) {
  .bot-parent {
    right: 5%;
  }
  .bot-msg {
    font-size: 16px;
  }
  .bot-logo {
    width: 20px;
  }
  .bot-btn {
    height: 60px;
    width: 60px;
    padding: 7px 9px;
  }
}
