.footer {
    display: flex;
    margin-top: 5%;
    color: white;
}

.antstack-logo {
    padding-top: 6%;
    padding-left: 2%;
}

@media  screen and (max-width: 767px) {
    .footer {
        margin-top: 2%;
    }

    .footer-text {
        padding-top: 0.5%;
        font-size: small;
    }

    .antstack-logo {
        width: 80%;
        padding-left: 2%;
    }
}