@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.ft-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 0;
  color: #ffffff;
  align-items: center;
  height: 100%;
}

.child-1 {
  margin: 0px 20px;
}
.child-2 {
  margin: 0px 20px;
}
.child-3 {
  margin: 0px 20px;
}
.child-4 {
  margin: 0px 20px;
}
.child-5 {
  margin: 0px 20px;
}
.child-6 {
  margin: 0px 20px;
}
.child-8 {
  margin: 0px 20px;
}
.child-7 {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  background: rgba(0, 0, 0, 0.3);
}
.ft-social-wrap {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ft-ant-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;
}
.ft-ant {
  width: 25px;
  margin: 0 4px -7px;
}
.ft-logo-head {
  display: flex;
  justify-content: center;
}
.ft-bz-logo {
  margin-right: 5px;
}

.ft-links p {
  margin: 5px;
}
.ft-socials {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ft-linkParent {
  display: flex;
  flex-direction: column;
}
.ft-child2 {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  align-items: center;

  margin-top: 70px;
}
.ft-social-lg {
  width: 10px;
  border: 1px solid #f7f7f7;
  padding: 5px 7px 5px 7px;
  border-radius: 5px;
}
.ft-social-inst {
  border: 1px solid #f7f7f7;
  padding: 7px 10px;
  border-radius: 5px;
  margin-right: 15px;
}
.ft-media-img {
  width: 15px;
}
.ft-social-instag {
  width: 15px;
  border: 1px solid #f7f7f7;
  padding: 5px;
  border-radius: 5px;
}
.ft-heart {
  width: 15px;
  margin-bottom: -3px;
}
.ft-copyright {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.ft-copyright-logo {
  margin-bottom: 10px;
}
.ft-link-title {
  font-size: 20px;
  margin-bottom: 40px;
}
.ft-privacy-policy:hover {
  cursor: pointer;
}
.ft-contact {
  text-decoration: none;
  color: #ffffff;
  padding: 0;
  margin-top: 18px;
}
.ft-contact:hover {
  cursor: pointer;
}
.ft-ant-logo-link {
  text-decoration: none;
  color: #ffffff;
}
.footer-items {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: normal;
  justify-content: space-evenly;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 767px) {
  .ft-parent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  .ft-social-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }
  .child-7 {
    width: 100%;
    margin-top: 0;
  }
  .ft-copyright {
    width: auto;
  }
  .ft-link-title {
    font-size: 20px;
    margin-bottom: 0;
  }
  .footer-items {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }
  .child-2 {
    grid-area: brand1;
    margin: 0px;
    text-align: center;
  }
  .child-3 {
    grid-area: brand2;
    margin: 0px;
    text-align: center;
  }
  .child-4 {
    grid-area: brand3;
    margin: 0px;
    text-align: center;
  }
  .child-5 {
    grid-area: brand4;
    margin: 0px;
    text-align: center;
  }
  .child-6 {
    grid-area: brand5;
    margin: 0px;
    text-align: center;
  }
  .child-8 {
    grid-area: brand6;
    margin: 0px;
    text-align: center;
  }
  .footer-items > div {
    text-align: center;
  }
}
